import React, {useEffect, useRef, useState} from 'react';
import 'moment/locale/de';
import 'moment/locale/es';
import 'moment/locale/pt';
import 'moment/locale/ru';
import 'moment/locale/tr';
import 'moment/locale/uk';
import {
  getFromLocalStorage,
  isolateNumbers,
  useCountries,
  useTheme,
  useTtag
} from 'whatcrm-core';
import {useTranslation} from 'react-i18next';
import moment from 'moment';

import {Notification, Preloader, Tone} from 'components';
import {useGetFfmpeg, useGetInstances, useRequest} from 'common/hooks';
import * as AppEnv from 'app-env';
import AppContext from 'app-context';
import Router from 'Router';

interface UrlParams {
  chat_id?: string;
  code?: string;
  domain?: string;
  email?: string;
  key?: string;
  phone_numbers?: string;
  theme?: string;
  user?: string;
  usernames?: string;
  version?: string;
}

const urlSearchParams = new URLSearchParams(window.location.search);
const urlParams: UrlParams = Object.fromEntries(urlSearchParams.entries());

const filter = (value: string, index: number, array: string[]) =>
  array.indexOf(value) == index;

const App = () => {
  const [chatIdList, setChatIdList] = useState<string[]>();
  const [client, setClient] = useState<AppEnv.WebAuth | null>();
  const [isCanPlayOgg, setIsCanPlayOgg] = useState(false);
  const [notification, setNotification] = useState<AppEnv.Notification>();
  const [phoneNumberList, setPhoneNumberList] = useState<string[]>();
  const [usernameList, setUsernameList] = useState<string[]>();

  const {fetchWebAuth} = useRequest();
  const {i18n} = useTranslation();
  const {theme, setTheme} = useTheme();
  const getFfmpeg = useGetFfmpeg();
  const getInstances = useGetInstances();
  const toneRef = useRef<HTMLAudioElement>(null);
  useCountries(i18n.resolvedLanguage);
  useTtag(i18n.resolvedLanguage || 'en');

  const getWebAuthParams = () => {
    const user = urlParams.user || urlParams.email || urlParams.key;

    if (!user) return {email: getFromLocalStorage('email')};

    const chatIdList = urlParams.chat_id?.split(',').filter(filter);
    const phoneNumbersList = urlParams.phone_numbers?.split(',').filter(filter);
    const usernamesList = urlParams.usernames?.split(',').filter(filter);

    setChatIdList(
      chatIdList?.map(item => item.replaceAll('+', '').replaceAll(' ', ''))
    );

    setPhoneNumberList(phoneNumbersList?.map(item => isolateNumbers(item)));
    setUsernameList(usernamesList?.map(item => item.replaceAll('@', '')));

    return {
      code: urlParams.code || null,
      domain: urlParams.domain,
      email: user,
      version: urlParams.version
    };
  };

  const getClient = async () => {
    const {code, domain, email, version} = getWebAuthParams();
    if (typeof code == 'object' || !email) return setClient(null);

    const res = await fetchWebAuth(email, domain, code, version);
    if (!res) return setClient(null);

    const instances = await getInstances(res);
    setClient({...res, instances});
  };

  useEffect(() => {
    const audio = new Audio();
    const canPlayType = audio.canPlayType('audio/ogg; codecs=opus');

    setIsCanPlayOgg(!!canPlayType);
  }, []);

  useEffect(() => {
    getClient();

    if (!navigator || !('serviceWorker' in navigator)) return;
    navigator.serviceWorker.register('/service-worker.js');
  }, []);

  useEffect(() => {
    const {theme} = urlParams;

    if (!theme) return;
    else if (theme == '0') setTheme('system');
    else if (theme == '1') setTheme('light');
    else if (theme == '2') setTheme('dark');
  }, []);

  const isIframe = !!(chatIdList || phoneNumberList || usernameList);
  moment.locale(i18n.resolvedLanguage);

  return (
    <>
      {typeof client == 'object' ? (
        <AppContext.Provider
          value={{
            chatIdList,
            getFfmpeg,
            isCanPlayOgg,
            isIframe,
            phoneNumberList,
            setNotification,
            setTheme,
            theme,
            toneRef,
            usernameList
          }}
        >
          <div className="app">
            <Notification notification={notification} />
            <Router client={client} setClient={setClient} />
            <Tone ref={toneRef} />
          </div>
        </AppContext.Provider>
      ) : (
        <Preloader isMaxHeight />
      )}
    </>
  );
};

export default App;
