import React, {useEffect, useRef, useState} from 'react';
import {findPosition, useOnClickOutside} from 'whatcrm-core';
import {Picker} from 'emoji-mart';
import {useTranslation} from 'react-i18next';
import cn from 'classnames';
import data from '@emoji-mart/data';

import {useSendReaction} from '../hooks';
import * as AppEnv from 'app-env';

interface Emoji {
  native: string;
}

interface TriggerPostion {
  left: number;
  top: number;
}

interface Position {
  top: number;
  left: number;
}

interface ReactionPicker {
  isReactionPickerActive: boolean;
  setIsReactionPickerActive: AppEnv.SetState<boolean>;
  triggerPosition: TriggerPostion | undefined;
  triggerRef: React.RefObject<HTMLDivElement>;
}

const ReactionPicker = ({
  isReactionPickerActive,
  setIsReactionPickerActive,
  triggerPosition,
  triggerRef
}: ReactionPicker) => {
  const [position, setPosition] = useState<Position>();

  const {i18n} = useTranslation();
  const containerRef = useRef<HTMLDivElement>(null);
  const sendReaction = useSendReaction();
  const targetRef = useRef<HTMLDivElement>(null);

  useOnClickOutside(targetRef, {
    onClick: () => setIsReactionPickerActive(false),
    trigger: triggerRef
  });

  useEffect(() => {
    if (!isReactionPickerActive) return;

    const res = findPosition(targetRef, {containerRef, triggerPosition});
    setPosition(res);
  }, [isReactionPickerActive]);

  const handleEmojiSelect = (e: Emoji) => {
    setIsReactionPickerActive(false);
    sendReaction(e.native);
  };

  useEffect(() => {
    // eslint-disable-next-line
    const trans = require(
      `@emoji-mart/data/i18n/${i18n.resolvedLanguage}.json`
    );

    new Picker({
      data,
      dynamicWidth: true,
      i18n: trans,
      onEmojiSelect: handleEmojiSelect,
      previewPosition: 'none',
      ref: targetRef,
      searchPosition: 'none',
      skin: '1'
    });
  }, []);

  const left = position?.left ? position.left - 8 : undefined;

  return (
    <div
      className={cn('reaction-picker', {
        'reaction-picker_active': isReactionPickerActive
      })}
      ref={containerRef}
    >
      <div
        className="reaction-picker__mart"
        ref={targetRef}
        style={{...position, left}}
      />
    </div>
  );
};

export default ReactionPicker;
