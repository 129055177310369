import {useTranslation} from 'react-i18next';
import {v4 as uuid} from 'uuid';
import axios from 'axios';

import {useAppContext} from 'app-context';
import * as AppEnv from 'app-env';

interface AxiosResponse extends Response {
  status?: string;
}

interface Reject {
  code: string;
  response?: AxiosResponse;
}

interface RequestParams {
  body?: object;
  data?: object;
  isFormData?: boolean;
  method?: 'get' | 'post';
  noNotification?: boolean;
  params?: object;
  timeout?: number;
}

interface Response {
  data: any; // eslint-disable-line
}

const useRequest = () => {
  const {setNotification = () => {}} = useAppContext();
  const {t} = useTranslation();

  const request = async <T>(
    path: string,
    {
      data,
      isFormData,
      method = 'get',
      noNotification,
      params,
      timeout
    }: RequestParams = {}
  ): Promise<T | null> => {
    const controller = new AbortController();

    const instance = axios.create({
      baseURL: process.env.REACT_APP_API,
      headers: {
        'Content-Type': isFormData ? 'multipart/form-data' : 'application/json',
        'X-Whappim-Token': process.env.REACT_APP_API_TOKEN
      },
      signal: controller.signal
    });

    if (timeout) setTimeout(() => controller.abort(), timeout);

    const res = await instance(path, {data, method, params})
      .then((response: Response) => {
        if (
          typeof response.data != 'object' ||
          (!('error_code' in response.data) && !('error_text' in response.data))
        )
          return response.data;

        const errorCode = response.data.error_code
          ? ` ${response.data.error_code}`
          : '';

        if (!noNotification)
          setNotification({
            title: t('Error{{errorCode}}', {errorCode}),
            text: response.data.error_text
          });

        return null;
      })
      .catch(({code, response}: Reject) => {
        if (code == 'ERR_CANCELED') return null;

        let errorCode = response?.data.error_code || response?.status || '';
        errorCode = errorCode ? ` ${errorCode}` : '';

        if (!noNotification)
          setNotification({
            title: t('Error{{errorCode}}', {errorCode}),
            text: response?.data.error_text || t`Something went wrong.`
          });

        return null;
      });

    return res;
  };

  interface FetchChatFree {
    success: 0 | 1;
  }

  const fetchChatFree = (id: string) =>
    request<FetchChatFree>('v3/chat/free', {params: {id}});

  const fetchChatInfo = (id: string) =>
    request<AppEnv.ChatInfo>('v3/chat/info', {params: {id}});

  const fetchChatRead = (chat_key: string, user_id: string, chatId: string) =>
    request(`v3/instance${chat_key}/chatRead`, {params: {chatId, user_id}});

  const fetchCreatePrivateChat = (chat_key: string, chatId: number) =>
    request<AppEnv.TelegramDialog>(`v3/instance${chat_key}/createPrivateChat`, {
      noNotification: true,
      params: {chatId}
    });

  const fetchDeleteMessage = (
    chat_key: string,
    chatId: number | string,
    messageId: number | string,
    revoke: boolean
  ) =>
    request<null>(`v3/instance${chat_key}/deleteMessage`, {
      params: {chatId, messageId, revoke: revoke ? 1 : 0}
    });

  const fetchDeleteMessageAvito = (
    chat_key: string,
    user_id: string,
    chat_id: string,
    message_id: string
  ) =>
    request(`v3/instance${chat_key}/deleteMessage`, {
      params: {chat_id, message_id, user_id}
    });

  const fetchDialog = (
    chat_key: string,
    chatId: number | string,
    noNotification?: boolean
  ) =>
    request<AppEnv.Dialog>(`v3/instance${chat_key}/dialog`, {
      noNotification,
      params: {chatId}
    });

  const fetchDialogs = (chat_key: string) =>
    request<AppEnv.Dialog[]>(`v3/instance${chat_key}/dialogs`);

  interface FetchDownloadMedia {
    url: string;
  }

  const fetchDownloadMedia = (
    chat_key: string,
    messageId: string,
    mediaKey: string
  ) =>
    request<FetchDownloadMedia | []>(`v3/instance${chat_key}/downloadMedia`, {
      params: {mediaKey, messageId}
    });

  interface FetchGetNumberId {
    user: string;
  }

  const fetchGetNumberId = (chat_key: string, phone: string) =>
    request<FetchGetNumberId>(`v3/instance${chat_key}/getNumberId`, {
      noNotification: true,
      params: {phone}
    });

  const fetchInstanceCheckAuthenticationCode = (
    chat_key: string,
    code: string
  ) =>
    request(`v3/instance${chat_key}/checkAuthenticationCode`, {params: {code}});

  const fetchInstanceCheckAuthenticationPassword = (
    chat_key: string,
    password: string
  ) =>
    request(`v3/instance${chat_key}/checkAuthenticationPassword`, {
      params: {password}
    });

  const fetchInstanceDestroy = (chat_key: string) =>
    request(`v3/instance${chat_key}/destroy`);

  const fetchInstanceRequestQrCodeAuthentication = (chat_key: string) =>
    request(`v3/instance${chat_key}/requestQrCodeAuthentication`);

  interface FetchInstanceSetAuthenticationPhoneNumber {
    code?: string;
  }

  const fetchInstanceSetAuthenticationPhoneNumber = (
    chat_key: string,
    phone: string
  ) =>
    request<FetchInstanceSetAuthenticationPhoneNumber>(
      `v3/instance${chat_key}/setAuthenticationPhoneNumber`,
      {params: {phone}}
    );

  interface FetchInstanceStatus extends AppEnv.InstanceCondition {
    state: AppEnv.InstanceStatus;
  }

  const fetchInstanceStatus = (chat_key: string, full?: 1) =>
    request<FetchInstanceStatus>(`v3/instance${chat_key}/status`, {
      params: {full}
    });

  const fetchMe = (chat_key: string) =>
    request<AppEnv.Me>(`v3/instance${chat_key}/me`);

  const fetchMessages = (
    chat_key: string,
    chatId: number | string,
    version: AppEnv.InstanceVersion,
    fromMessageId: number | undefined,
    maxTimestamp: number | undefined,
    offset: number | undefined,
    noNotification: boolean
  ) =>
    request<AppEnv.AvitoMessages | AppEnv.Message[]>(
      `v3/instance${chat_key}/messages`,
      {
        noNotification,
        params: {
          chatId,
          fromMessageId: version == 'telegram' ? fromMessageId || 0 : undefined,
          limit: version == 'avito' || version == 'telegram' ? 10 : undefined,
          maxTimestamp: version == 'whatcrm' ? maxTimestamp : undefined,
          offset: version == 'avito' ? offset : undefined
        }
      }
    );

  interface FetchPinChat {
    result: boolean;
  }

  const fetchPinChat = (chat_key: string, chatId: number | string) =>
    request<FetchPinChat>(`v3/instance${chat_key}/pinChat`, {params: {chatId}});

  const fetchProfileImage = async (
    chat_key: string,
    chatId: number | string
  ) => {
    const res = await request<string>(`v3/instance${chat_key}/profileImage`, {
      noNotification: true,
      params: {chatId}
    });

    return res == 'https://api.whatcrm.net/img/avatar.png' ? null : res;
  };

  interface FetchReadChat {
    _?: 'ok';
    result?: boolean;
  }

  const fetchReadChat = (chat_key: string, chatId: number | string) =>
    request<FetchReadChat>(`v3/instance${chat_key}/readChat`, {
      params: {chatId}
    });

  const fetchRemoteFile = (chat_key: string, remoteFileId: string) =>
    request<AppEnv.TelegramRemoteFile>(`v3/instance${chat_key}/getRemoteFile`, {
      params: {remoteFileId}
    });

  const fetchSearchPublicChat = (chat_key: string, username: string) =>
    request<AppEnv.TelegramDialogShape>(
      `v3/instance${chat_key}/searchPublicChat`,
      {params: {username}}
    );

  interface FetchSearchUserByPhoneNumber {
    firstName: string;
    id: number;
    lastName: string;
    username: string;
  }

  const fetchSearchUserByPhoneNumber = (chat_key: string, phone: string) =>
    request<FetchSearchUserByPhoneNumber>(
      `v3/instance${chat_key}/searchUserByPhoneNumber`,
      {params: {phone}}
    );

  const fetchTariffs = (
    crm: AppEnv.Crm,
    domain: string,
    currency: 'RUB' | 'USD'
  ) =>
    request<AppEnv.Tariffs>('v3/tariffs', {
      params: {crm, currency, domain, group: 1}
    });

  interface FetchUnpinChat {
    result: boolean;
  }

  const fetchUnpinChat = (chat_key: string, chatId: number | string) =>
    request<FetchUnpinChat>(`v3/instance${chat_key}/unpinChat`, {
      params: {chatId}
    });

  interface FetchUnreadChat {
    result: boolean;
  }

  const fetchUnreadChat = (chat_key: string, chatId: number | string) =>
    request<FetchUnreadChat>(`v3/instance${chat_key}/unreadChat`, {
      params: {chatId}
    });

  const fetchUser = (
    chat_key: string,
    userId: number,
    noNotification?: boolean
  ) =>
    request<AppEnv.TelegramUser>(`/v3/instance${chat_key}/user`, {
      noNotification,
      params: {userId}
    });

  const fetchWebAuth = (
    email: string,
    domain?: string,
    code?: string,
    version?: string
  ) =>
    request<AppEnv.WebAuth>('v3/web/auth', {
      params: {
        code,
        domain,
        email,
        full_access: domain ? true : undefined,
        version
      }
    });

  const postBlacklistCreate = (integration_id: number, phone: string) =>
    request<AppEnv.Blacklist>('v3/blacklist/create', {
      data: {phone},
      method: 'post',
      params: {integration_id}
    });

  interface PostChatSpare {
    chat_id: string;
    chat_key: string;
    date_pay: number;
    date_subscription: number;
    date_trial: number;
    id: number;
    is_premium: 0 | 1;
    label: string;
    name: string;
    phone: string;
    status: AppEnv.InstanceStatus;
    tariff_plane: AppEnv.TariffPlan;
    version: AppEnv.InstanceVersion;
    webhook: string | null;
  }

  const postChatSpare = (
    crm: string,
    domain: string,
    version: AppEnv.InstanceVersion,
    label?: string
  ) =>
    request<PostChatSpare>('v3/chat/spare', {
      data: {label},
      method: 'post',
      params: {crm, domain, version}
    });

  interface PostChatUpdateData {
    label?: string;
    name?: string;
    webhook?: string;
    phone?: string;
  }

  interface PostChatUpdate {
    label?: string;
    webhook?: string;
    name?: string;
    phone?: string;
  }

  const postChatUpdate = (id: string, data: PostChatUpdateData) =>
    request<PostChatUpdate>('v3/chat/update', {
      data,
      method: 'post',
      params: {id}
    });

  interface PostEditMessage {
    edit: true;
  }

  const postEditMessage = (
    chat_key: string,
    chatId: number | string,
    messageId: number | string,
    body: string
  ) =>
    request<PostEditMessage | object>(
      `v3/instance${chat_key}/editMessage${
        typeof chatId == 'number' ? 'Text' : ''
      }`,
      {method: 'post', data: {chatId, messageId, body}}
    );

  interface PostEmailPropCode {
    success: number;
  }

  const postEmailPropCode = (code: string, email: string) =>
    request<PostEmailPropCode>('v3/email/prop-code', {
      method: 'post',
      data: {code, email}
    });

  interface PostEmailSendMd5 {
    message: string;
    success: number;
  }

  const postEmailSendMd5 = (email: string) =>
    request<PostEmailSendMd5>('v3/email/send-md5', {
      method: 'post',
      data: {email, generate_code: 1}
    });

  const postFilesLoadData = (blobValue: Blob | File, filename: string) => {
    const formData = new FormData();

    const uniq = uuid().replaceAll('-', '_');
    const uniqueFilename = `${uniq}_${filename}`;

    formData.append('body', blobValue, uniqueFilename);

    return request<string>('v3/files/load-data', {
      data: formData,
      isFormData: true,
      method: 'post'
    });
  };

  const postForwardMessage = (
    chat_key: string,
    fromChatId: number | string,
    chatId: string,
    messageId: number | string
  ) =>
    request(`v3/instance${chat_key}/forwardMessage`, {
      data: {chatId, fromChatId, messageId},
      method: 'post',
      noNotification: true
    });

  interface PostIntegrationsUpdate {
    is_manager: 0 | 1;
  }

  const postIntegrationsUpdate = (
    crm: AppEnv.Crm,
    domain: string,
    is_manager: 0 | 1
  ) =>
    request<PostIntegrationsUpdate>(`v3/integrations/update`, {
      data: {is_manager},
      method: 'post',
      params: {crm, domain}
    });

  const postReaction = (
    chat_key: string,
    chatId: number | string,
    messageId: number | string,
    reaction: string
  ) =>
    request(`v3/instance${chat_key}/reaction`, {
      method: 'post',
      data: {chatId, messageId, reaction}
    });

  interface PostRemoveChat {
    _?: 'ok';
    result?: boolean;
  }

  const postRemoveChat = (chat_key: string, chatId: number | string) =>
    request<PostRemoveChat>(`v3/instance${chat_key}/removeChat`, {
      data: {chatId},
      method: 'post'
    });

  interface PostSendMessage {
    message_id?: string;
    sent?: boolean;
  }

  interface PostSendFileParams {
    caption?: string;
    chatId?: number | string;
    duration?: number;
    filename?: string;
    quotedMsgId?: number | string;
    username?: string;
    waveform?: string;
  }

  const postSendFile = (
    chat_key: string,
    body: string,
    params?: PostSendFileParams
  ) =>
    request<PostSendMessage>(`v3/instance${chat_key}/sendFile`, {
      data: {...params, body},
      method: 'post'
    });

  interface Button {
    body: string;
  }

  interface PostSendMessageParams {
    allowMultipleAnswers?: boolean;
    buttons?: Button[];
    chatId?: number | string;
    quotedMsgId?: number | string;
    username?: string;
  }

  const postSendMessage = (
    chat_key: string,
    body: string,
    params: PostSendMessageParams
  ) =>
    request<PostSendMessage | AppEnv.AvitoMessage>(
      `v3/instance${chat_key}/sendMessage`,
      {data: {...params, body}, method: 'post'}
    );

  return {
    fetchChatFree,
    fetchChatInfo,
    fetchChatRead,
    fetchCreatePrivateChat,
    fetchDeleteMessage,
    fetchDeleteMessageAvito,
    fetchDialog,
    fetchDialogs,
    fetchDownloadMedia,
    fetchGetNumberId,
    fetchInstanceCheckAuthenticationCode,
    fetchInstanceCheckAuthenticationPassword,
    fetchInstanceDestroy,
    fetchInstanceRequestQrCodeAuthentication,
    fetchInstanceSetAuthenticationPhoneNumber,
    fetchInstanceStatus,
    fetchMe,
    fetchMessages,
    fetchPinChat,
    fetchProfileImage,
    fetchReadChat,
    fetchRemoteFile,
    fetchSearchPublicChat,
    fetchSearchUserByPhoneNumber,
    fetchTariffs,
    fetchUnpinChat,
    fetchUnreadChat,
    fetchUser,
    fetchWebAuth,
    postBlacklistCreate,
    postChatSpare,
    postChatUpdate,
    postEditMessage,
    postEmailPropCode,
    postEmailSendMd5,
    postFilesLoadData,
    postForwardMessage,
    postIntegrationsUpdate,
    postReaction,
    postRemoveChat,
    postSendFile,
    postSendMessage
  };
};

export default useRequest;
