import React from 'react';
import cn from 'classnames';

interface Anchor {
  children: React.ReactNode;
  isDisabled?: boolean;
  onClick?: () => void;
  size?: 16;
  textAlign?: 'center';
}

const Anchor = ({children, isDisabled, onClick, size, textAlign}: Anchor) => (
  <button
    className={cn('anchor', {[`anchor_${size}`]: size})}
    disabled={isDisabled}
    onClick={() => (onClick ? onClick() : undefined)}
    style={{textAlign}}
    type="button"
  >
    {children}
  </button>
);

export default Anchor;
