import {getDialogId} from 'common/actions';
import {useChatContext} from '../../chat-context';
import {useDispatchContext} from 'pages/workspace/messenger/dispatch/dispatch-context';
import {useRequest, useUploadVoice} from 'common/hooks';
import * as AppEnv from 'app-env';
import * as FooterEnv from '../footer-env';

const useSendVoice = (setIsVoiceRecording: AppEnv.SetState<boolean>) => {
  const {instance} = useDispatchContext();
  const {dialog, quotedMsg} = useChatContext();

  const {postSendFile} = useRequest();
  const uploadVoice = useUploadVoice(dialog.version);

  const sendVoice = async (values: FooterEnv.Values) => {
    if (!values.voice) return false;

    const {voice} = values;
    const src = await uploadVoice(voice.blob);
    if (!src) return false;

    const chatId = getDialogId(dialog, true);
    const username = dialog.version == 'telegram' ? dialog.username : undefined;
    const id = username ? {username} : {chatId: chatId || undefined};

    await postSendFile(instance.chat_key, src, {
      ...id,
      duration: voice.duration,
      quotedMsgId: quotedMsg?.message.id,
      waveform: voice.waveform
    });

    setIsVoiceRecording(false);
    return true;
  };

  return sendVoice;
};

export default useSendVoice;
