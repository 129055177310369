import React, {forwardRef} from 'react';
import {useFormikContext} from 'formik';

import {Icons, SaveButton, Voice} from 'components';
import {useChatContext} from '../../chat-context';
import {useDispatchContext} from 'pages/workspace/messenger/dispatch/dispatch-context';
import {useFooterContext} from '../footer-context';
import * as FooterEnv from '../footer-env';

import Emoji from './emoji/emoji';
import PaperClip from './paper-clip/paper-clip';
import Text from './text/text';

interface MessageInput {
  textRef: React.RefObject<HTMLTextAreaElement>;
}

const MessageInput = forwardRef<HTMLTextAreaElement, MessageInput>(
  ({textRef}, ref) => {
    const {instance} = useDispatchContext();
    const {quotedMsg} = useChatContext();

    const {isAttachsFormActive, isVoiceRecording, setIsVoiceRecording} =
      useFooterContext();

    const {values} = useFormikContext<FooterEnv.Values>();
    const isSendButtonShown = !!values.text && !isAttachsFormActive;

    return (
      <div className="footer__message-input">
        {!isVoiceRecording && (
          <>
            <PaperClip />
            <Text ref={ref} />
            <Emoji textRef={textRef} />
          </>
        )}

        {isSendButtonShown ? (
          <SaveButton color="transparent">
            <Icons.PaperPlaneRight />
          </SaveButton>
        ) : (
          <Voice
            isDisabled={quotedMsg?.isEditing}
            isVoiceRecording={isVoiceRecording}
            setIsVoiceRecording={setIsVoiceRecording}
            version={instance.version}
          />
        )}
      </div>
    );
  }
);

MessageInput.displayName = 'MessageInput';
export default MessageInput;
