import React, {useState} from 'react';
import {Form, Formik, FormikHelpers} from 'formik';

import {useBitrix24Request, useUploadVoice} from 'common/hooks';
import {Voice} from 'components';
import * as AppEnv from 'app-env';

import {useUrlParams} from './hooks';

interface Values {
  voice: AppEnv.Voice | undefined;
}

interface Record {
  client: AppEnv.WebAuth | null;
}

const Record = () => {
  const [isVoiceRecording, setIsVoiceRecording] = useState(false);
  const {fetchVoiceMessage} = useBitrix24Request();

  const urlParams = useUrlParams();
  const version = urlParams.version || 'whatcrm';

  const uploadVoice = useUploadVoice(version);

  const initialValues: Values = {
    voice: undefined
  };

  const handleSubmit = async (
    values: Values,
    helpers: FormikHelpers<Values>
  ) => {
    const key = urlParams.key;
    if (!key) return true;

    const {voice} = values;
    if (!voice) return true;

    const src = await uploadVoice(voice.blob);
    if (!src) return true;

    await fetchVoiceMessage(key, src);

    helpers.resetForm();
    setIsVoiceRecording(false);

    return true;
  };

  return (
    <div className="voice-page">
      <Formik initialValues={initialValues} onSubmit={handleSubmit}>
        <Form>
          <Voice
            isAlt
            isVoiceRecording={isVoiceRecording}
            setIsVoiceRecording={setIsVoiceRecording}
            version={version}
          />
        </Form>
      </Formik>
    </div>
  );
};

export default Record;
