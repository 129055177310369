import React from 'react';

import {DocIcon, Icons, Wrapper} from 'components';
import {MessageText} from '../user-message';
import {useMessageContext} from '../../../message-context';
import {useConvertBytes} from 'common/hooks';

interface MessageDocument {
  caption?: string;
  fileName?: string;
  size?: number;
  isLoading?: boolean;
  onClick: () => void;
}

const MessageDocument = ({
  caption,
  fileName,
  size,
  isLoading,
  onClick
}: MessageDocument) => {
  const {message} = useMessageContext();
  const convertBytes = useConvertBytes();

  return (
    <Wrapper direction="column" gap={8}>
      <button
        className="message__download"
        title={fileName}
        disabled={isLoading}
        onClick={onClick}
      >
        <Wrapper alignItems="center" gap={12} noWrap>
          <DocIcon fileName={fileName} />

          <div style={{overflow: 'hidden'}}>
            {fileName && (
              <MessageText isBold noWrap>
                {fileName}
              </MessageText>
            )}

            <Wrapper alignItems="center" gap={4}>
              {isLoading ? (
                <Icons.CircleNotch color="blue" size={20} />
              ) : (
                <Icons.ArrowDown color="blue" size={20} />
              )}

              {size != undefined && (
                <MessageText isBold isLight>
                  {convertBytes(
                    size,
                    typeof message.id == 'string' ? 1000 : undefined
                  )}
                </MessageText>
              )}
            </Wrapper>
          </div>
        </Wrapper>
      </button>

      {caption && <MessageText>{caption}</MessageText>}
    </Wrapper>
  );
};

export default MessageDocument;
