import React from 'react';
import {Navigate, Route, Routes, useLocation} from 'react-router-dom';
import {useScrollClassName} from 'whatcrm-core';
import {useTranslation} from 'react-i18next';
import cn from 'classnames';

import {Nav, Title} from 'components';

import Appearance from './appearance/appearance';
import AsideFooter from './aside-footer/aside-footer';
import General from './general/general';

const Aside = () => {
  const {t} = useTranslation();
  const location = useLocation();
  const scrollClassName = useScrollClassName();

  const options = [
    {label: t`General`, value: '/widget/general'},
    {label: t`Appearance`, value: '/widget/appearance'}
  ];

  return (
    <div className="aside">
      <div className="aside__header">
        <Title.H3 style={{marginBottom: 16}} isTextAlignCenter>
          {t`Web widget settings`}
        </Title.H3>

        <Nav options={options} value={location.pathname} />
      </div>

      <div className={cn('aside__body', {[scrollClassName]: scrollClassName})}>
        <Routes>
          <Route path="/appearance" element={<Appearance />} />
          <Route path="/general" element={<General />} />
          <Route path="*" element={<Navigate to="/widget/general" />} />
        </Routes>
      </div>

      <AsideFooter />
    </div>
  );
};

export default Aside;
