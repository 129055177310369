import React, {forwardRef, useRef, useState} from 'react';
import {LongPressReactEvents, useLongPress} from 'use-long-press';
import {useTranslation} from 'react-i18next';
import cn from 'classnames';
import moment from 'moment';

import {Ack, Text, Wrapper} from 'components';
import ProfileImage from 'pages/workspace/messenger/dispatch/components/profile-image/profile-image';
import {useChatContext} from '../../../../chat-context';
import {useDispatchContext} from 'pages/workspace/messenger/dispatch/dispatch-context';
import {useGetMessageSenderName} from 'common/hooks';
import {useIsOnline} from '../../../../hooks';
import {useMessageContext} from '../../message-context';
import {
  getDialogId,
  getDialogProfileImage,
  getIsLimited,
  getIsMessageOutgoing
} from 'common/actions';

import ContextMenuProvider from './context-menu-provider/context-menu-provider';
import CtwaContext from './ctwa-context/ctwa-context';
import InnerMessage from './inner-message/inner-message';
import QuotedMessage from './quoted-message/quoted-message';
import Reactions from './reactions/reactions';
import getIsMessageEdited from './get-is-message-edited';

interface TriggerPosition {
  left: number;
  top: number;
}

interface UserMessage {
  children: React.ReactNode;
}

const UserMessage = forwardRef<HTMLDivElement, UserMessage>(
  ({children}, ref) => {
    const {instance} = useDispatchContext();
    const {dialog} = useChatContext();
    const {message} = useMessageContext();

    const [isContextMenuActive, setIsContextMenuActive] = useState(false);
    const [triggerPosition, setTriggerPosition] = useState<TriggerPosition>();

    const {t} = useTranslation();

    const bindLongPress = useLongPress(e => handleContextMenu(e), {
      cancelOnMovement: true
    });

    const bubbleRef = useRef<HTMLDivElement>(null);
    const getMessageSenderName = useGetMessageSenderName();
    const isOnline = useIsOnline();

    const getIsForwarded = () => {
      if ('senderId' in message) return 'forwardInfo' in message;
      else if ('_data' in message) return message.isForwarded;
    };

    const getTimestamp = () => {
      if ('author_id' in message) return message.created;
      else if ('senderId' in message || 'from' in message)
        return message.timestamp;
    };

    const handleContextMenu = (
      e: MouseEvent | LongPressReactEvents<Element>
    ) => {
      if ((e.target as HTMLElement).hasAttribute('srcset')) return;
      else if ('pageX' in e) setTriggerPosition({left: e.pageX, top: e.pageY});

      setIsContextMenuActive(true);
      e.preventDefault();
    };

    const isEdited = getIsMessageEdited(message);
    const isForwarded = getIsForwarded();
    const isLimited = getIsLimited(instance);
    const isOutgoing = getIsMessageOutgoing(dialog, message);
    const isOnlineTag = isOnline && !isOutgoing;
    const senderName = getMessageSenderName(dialog, message);

    const src = isOutgoing
      ? instance.profileImage
      : getDialogProfileImage(instance, dialog);

    const timestamp = getTimestamp();

    return (
      <div
        className={cn('message', {message_outgoing: isOutgoing})}
        data-id={message.id.toString()}
        ref={ref}
      >
        <ProfileImage
          id={isOutgoing ? instance.id : getDialogId(dialog)}
          name={senderName}
          src={src}
          tag={isOnlineTag ? 'online' : undefined}
        />

        <div className="message__wrapper">
          <Text
            className="message__sender"
            size={16}
            fontWeight={600}
            style={{marginBottom: 4}}
          >
            {senderName}
          </Text>

          <div
            {...bindLongPress()}
            className={cn('message__bubble', {
              message__bubble_edited: isEdited
            })}
            ref={bubbleRef}
            onContextMenu={handleContextMenu}
          >
            <div style={{overflow: 'hidden'}}>
              <Wrapper direction="column" gap={12} style={{overflow: 'hidden'}}>
                <QuotedMessage />

                <div style={{maxWidth: '100%'}}>
                  {isForwarded ? (
                    <InnerMessage>{children}</InnerMessage>
                  ) : (
                    <Wrapper
                      direction="column"
                      gap={8}
                      style={{overflow: 'hidden'}}
                    >
                      {children}
                      {'_data' in message && <CtwaContext message={message} />}
                    </Wrapper>
                  )}
                </div>
              </Wrapper>

              {isEdited && <Text color="secondary" size={12}>{t`Edited`}</Text>}
            </div>

            {isOutgoing && (
              <Ack dialog={dialog} instance={instance} message={message} />
            )}
          </div>

          {!isLimited && <Reactions message={message} />}

          {timestamp && (
            <Text className="message__time" size={12}>
              {moment.unix(timestamp).format('LT')}
            </Text>
          )}
        </div>

        <ContextMenuProvider
          bubbleRef={bubbleRef}
          triggerPosition={triggerPosition}
          isContextMenuActive={isContextMenuActive}
          setIsContextMenuActive={setIsContextMenuActive}
        />
      </div>
    );
  }
);

UserMessage.displayName = 'UserMessage';

export {default as MessageAudio} from './message-audio/message-audio';
export {default as MessageDocument} from './message-document/message-document';
export {default as MessageLocation} from './message-location/message-location';
export {default as MessageProduct} from './message-product/message-product';
export {default as MessageText} from './message-text/message-text';

export default UserMessage;
